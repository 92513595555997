.modal{
  display:block;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content{
  background-color: $blue;
  background-color: var(--mainColor);
  color: #FFF;
  @include modal;
}

.modal-header{
  border-bottom:none;
  padding: 20px;
  .link{
    color:#FFF;
    font-size:24px;
  }
}
.modal-title{
  text-transform: uppercase;
}
.modal-body{
  padding: 0 20px 20px 20px;
}

.modal-backdrop{
  background-color: $blue;
  background-color: var(--mainColor);
}