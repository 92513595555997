%font-regular {
  font-family: teuton_magerregular, Calibri, "Arial Narrow", sans-serif;
  font-weight:normal;
}

%font-bold {
  font-family: teuton_magerbold, Calibri, "Arial Narrow", sans-serif;
  font-weight:normal;
}

.bold-uppercased{
  @extend %font-bold;
  text-transform: uppercase;
}


body{
  @extend %font-regular;
  font-size:16px;
  line-height: normal;

}

h1, h2, h3{
  @extend %font-bold;
}

h2 small{
  display: block;
  text-transform: none;
  @extend %font-regular;
  line-height: normal;
  font-size:16px;
}

ul{
  padding-left:20px;
}

a{
  &:focus{
    outline: none;
  }
}

.address{
}

button{
  appearance: none;
  border:none;
  background: none;
  &:focus{
    outline: none;
  }
}

//todo ikony v odkazech při hoveru zobrazují podtržení
a .fa{
  &, &:hover, &:hover:after {
    text-decoration: none;
  }
}

.link-decorated{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}
.link-undecorated{
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.uppercase{
  text-transform: uppercase;
}

.nowrap{
  white-space: nowrap;
}

.info{
  cursor: help;
  display: inline-block;
  padding-bottom: 0;
  border-bottom: dashed 1px rgba(255,255,255,.5);
}

.button{
  background-color: $blue;
  background-color: var(--mainColor);
  border: 1px solid $blue;
  border-color: rgba(255,255,255,.3);
  color: var(--white);
  text-transform: uppercase;
  padding:8px 15px 6px;
  line-height: 1;
  display: inline-block;
  text-decoration: none !important;
  @extend %font-bold;
  &:hover{
    background-color: var(--white);
    color: $blue;
    color: var(--mainColor);
    text-decoration: none;
  }

  &.transparent{
    background-color: transparent;
    &:hover{
      color: $grey;
      background-color: #FFF;
    }
  }

  &[disabled]{
    background-color: $bgGrey;
    color: $grey;
    border-color: $grey;
    cursor: not-allowed;
  }

  @include media-breakpoint-up(md){
    padding:4px 12px 3px;
  }
}

.buttonArea{
  border:1px solid rgba(255,255,255,.5);
  padding:15px 10px;
  cursor:pointer;
  &:hover{
    border-color: rgba(255,255,255,1);
  }
  & > :last-child{
    margin-bottom: 0;
  }
  &.active{
    background-color: rgba(255,255,255,.8);
    color: #333
  }
}

.buttonsAreaRow{
  margin-bottom: 30px;
  .buttonArea {
    max-width: 45%;
  }
}

.table{
  width: 100%;
  td, th{
    vertical-align: top;
    border-bottom: 1px dotted #FFF;
    border-right: 1px dotted rgba(255,255,255,.5);
    padding: 6px 11px 6px 5px;
    &:last-child{
      border-right:none;
    }
  }
  thead{
    text-transform: uppercase;
  }
  tbody {
    td{vertical-align: middle;}
    tr:last-child {
      & {
        td, th {
          border-bottom: none;
        }
      }
    }
  }
}

.table-wrapper{
  width: 100%;
  max-width: 100%;
  @include media-breakpoint-down(md) {
    overflow-x: auto;
  }
}

.tooltip{
  cursor: help;
}