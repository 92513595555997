@mixin modal {
  border-color: rgba(255, 255, 255, .5);
  box-shadow: 0 0 10px 4px rgba(255, 255, 255, .4);
}

@mixin dimension($width, $height){
  width: $width;
  height: $height;
}

@mixin relative($top: null, $left:null, $bottom:null, $right:null){
  position:relative;
  top: if($top, $top, null);
  left: if($left, $left, null);
  right: if($right, $right, null);
  bottom: if($bottom, $bottom, null);
}

@mixin absolute($top: null, $left:null, $bottom:null, $right:null){
  position:absolute;
  top: if($top, $top, null);
  left: if($left, $left, null);
  right: if($right, $right, null);
  bottom: if($bottom, $bottom, null);

}

@mixin pseudo{
  content:"";
  display: inline-block;
}

@mixin arrow-down($width, $height) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-top: $height solid transparent;
}

@mixin list-unstyled{
  padding-left:0;
  list-style: none;
  li{
    display: inline-block;
  }
}

@mixin button-hover{
  position:relative;
  &:after{
    display: none;
    @include pseudo;
    content: attr(title);
    @include absolute($bottom:-18px,$left:0);
    text-align:center;
    width:100%;
    font-size:9px;
  }
  &:hover:after{
    display: block;
  }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
    font-weight: 300;
    font-style: italic;
  }
  ::-moz-placeholder {
    color: $color;
    font-weight: 300;
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: $color;
    font-weight: 300;
    font-style: italic;
  }
  :-moz-placeholder {
    color: $color;
    font-weight: 300;
    font-style: italic;
  }
}