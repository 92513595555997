@each $index, $color in $colors {
  .bg-#{$index} {
    background-color: $blue;
    background-color: var(--#{$index});
  }

  .list .bg-#{$index}.image-wrap:after,
  .list .bg-#{$index}.list-title:after{
    border-top-color: $blue;
    border-top-color: var(--#{$index});
  }
}

:root {
  --blue: #{$blue};
  --white: #{$white};
  @each $index, $color in $colors {
    --#{$index}: #{$color};
  }
}


//=== Skins ==============================

.bg-color {
  transition: background-color 0.5s ease;
  color: var(--white);
  //default color
  background-color: $blue;
  background-color: var(--mainColor);
}

// === colors visibility =====
.bg-color11{
  a{
    color:#FFF;
  }
}