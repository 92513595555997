//Common form formatting

input, select, textarea {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, .5);
  padding: 5px 4px 4px;
  outline: none;
  color: #000;
  @include placeholder(#888);
}

fieldset {
  margin-bottom: 20px;
  max-width:360px;
  margin-left:auto;
  margin-right:auto;
  @include media-breakpoint-up(sm){
    max-width: none;
  }
  legend{
    font-size:18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
}

.form-wrapper{
  display: flex;
  justify-content: center;
  & > * {max-width:360px;}
}

.form {
  text-align: center;

  input, select, textarea, .input {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
    text-align: center;

    &[type='checkbox'] {
      display: inline-block;
      width: auto;
      margin-right: 10px;
      margin-bottom: 4px;
    }
  }

  textarea {
    text-align: left;
  }

  .datePicker {
    display: flex;
    justify-content: space-between;

    select {
      display: inline-block;
      margin: 0 5px 15px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  label {
    font-weight: bold;
  }

  .button {
    margin: 0 auto 10px;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error {
    color: #000;
    font-weight: 600;
  }

  .with-error {
    border-color: #444;
    margin-bottom: 5px;
  }

  //honeypot
  .confirm-field {
    @include absolute($top: -1000%, $left: -1000%);
    visibility: hidden;
  }
}