.print-only {
  display: none;
}

@media print {

  .print-only {
    display: block;
  }

  .header {
    position: static;
    margin-bottom: 20px;
    .col{
      flex-basis: unset;
    }
    .logo {
      font-size:110%;
      flex-basis: unset;
      flex-grow: 1;
      max-width: 100%;
      align-items: center;
      justify-content: space-between;
      .link {
        display: none;
      }
      .motto{
        font-size:14px;
      }
    }
  }

  .bg-color, [class*=bg-color] {
    background-color: transparent;
    color: #000;
  }

  .list .list-item .content {
    overflow: visible;
    border: none;
  }


  .footer, .header-background,
  .nav-icons,
  .list-header,
  .button, .detail-actions,
  .list.page-list.page-open .list-item,
  .list-item.detail-opened:after,
  .list .image-wrap:after {
    display: none !important;
  }
  .main {
    padding-top: 0;
  }

  .list-detail {
    padding: 0;
  }
}