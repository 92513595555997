.container-fluid{
  max-width: 1140px;
}

body{
  background-color: var(--white) !important;
  --header-height: 40px;
  --footer-height: 300px;
  @include media-breakpoint-up(md){
    --header-height: 60px;
    --footer-height: 300px;
  }
}

.header{
  width:100%;
  min-height: 60px;
  min-height: var(--header-height);
  padding: 5px 10px 5px 5px;
  position: fixed;
  z-index: 15;
  @include media-breakpoint-up(md){
    padding: 5px 15px;
  }
  @extend .bg-color;
  .container-fluid{
    padding:0;
  }

  .title{
    font-size:1.2rem;
    margin:5px 0 0 ;
    text-align: center;
    @include media-breakpoint-down(xs){
      padding-right: 5px;
    }
  }
  .logo{
    display:flex;
    align-items: center;
    @include media-breakpoint-up(sm){
      align-items: flex-end;
    }
    .link{
      display:inline-block;
      background-image: url(../images/ddmjm-logo-mobile.png);
      background-size: contain;
      background-repeat: no-repeat;
      @include dimension(50px,50px);
      width:calc(var(--header-height) - 10px);
      height: calc(var(--header-height) - 10px);
    }
    .motto{
      font-style: italic;
      font-size:12px;
      display: none;
    }
  }

  --nav-icons-height: 24px;
  .nav-icons{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--white);
    font-size: var(--nav-icons-height);
    [class^=fas]{
      margin-left:10px;
      @include media-breakpoint-up(md){
        margin-left:20px;
      }
    }
    & > *{
      height:var(--nav-icons-height);
      color: inherit;
    }
  }

  @include media-breakpoint-up(md){
    .title {
      font-size: 2rem;
      margin-top: 10px;
      display:block;
    }
    .logo{
      .link{
        background-image: url(../images/ddmjm-logo.png);
      }
      .motto{
        display:inline-block;
      }
    }
  }

}

.main{
  min-height: calc(100vh - 300px);
  min-height: calc(100vh - var(--footer-height));
  padding-top: 310px;
  padding-top: calc(250px + var(--header-height));
  padding-bottom:50px;
  position: relative;
  z-index:10;
  @include media-breakpoint-up(md){
    padding-top:266px;
    padding-top: calc(206px + var(--header-height))
  }
  @include media-breakpoint-up(lg){
    padding-top:409px;
    padding-top:calc(349px + var(--header-height));
  }
}

.header-background{
  top: 60px;
  @include absolute($top: var(--header-height));
  z-index:0;
  width:100%;
  height:230px;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center top;

  @include media-breakpoint-up(md){
    height: 440px;
  }

  @include media-breakpoint-up(lg){
    height: 583px;
  }
}

%partner-icon{
  img, i{
    opacity: .85;
  }
  &:hover{
    img, i {
      opacity: 1;
    }
  }
}

.footer{
  @extend .bg-color;
  min-height: 300px;
  min-height: var(--footer-height);
  padding: 40px 10px;
  position: relative;
  z-index:10;

  .partners{
    margin-top: 30px;
    margin-bottom: 30px;
    .partner{
      margin: 5px;
      @extend %partner-icon;
      img{
        height: 50px;
      }
    }

  }
  @include media-breakpoint-up(md){
    padding: 20px;
    .partners {
      margin: 0;
    }
  }
  a{
    @extend .link-undecorated;
    color: var(--white);
  }

  .nav-footer{
    text-transform: uppercase;
    ul{
      list-style-type: none;
      padding:0;
      margin:0;
      .link{
        display: inline-block;
        padding:3px 0;
        text-transform: uppercase;
        @extend .link-undecorated;
        color: var(--white);
      }
      .submenu{
        &:not(.open) {
          display: none;
        }
        margin-bottom: 15px;
        font-size:90%;
      }
    }
    @include media-breakpoint-up(md){
      text-align: right;
    }
  }
}


.info-modal{
  max-width:750px;
  width:90vw;
  a{
    color:var(--white);
    @extend .link-decorated;
  }

  table {
    @extend .table;
    margin-bottom: 30px;
  }
  .title{
    margin-bottom: 20px;
  }
}


.not-found{
  @include media-breakpoint-up(md) {
    @include relative($top: 50px);
    margin-top: 50px;
  }
}

.alert{
  margin: 15px auto 30px;
  max-width:400px;
  border: 1px solid rgba(255,255,255,.5);
  background-color: rgba(255,255,255,.3);
  color:#444;
  padding:20px;
  text-align:center;
  font-size:18px;
}
.modal{
  .alert{
    max-width: none;
  }
}

.notification{
  @include absolute($top: calc(var(--header-height) + 20px),$right:0);
  @include modal;
  position:fixed;
  max-width:300px;
  background-color: $blue;
  background-color: var(--mainColor);
  color:#444;
  padding: 10px 3px 10px 12px;
  display: flex;
  align-items: center;
  z-index:20;
  line-height: 1.4;
  .link{
    padding:0 6px 0 8px;
    color: rgba(0,0,0,.5);

  }
  a{
    @extend .link-decorated;
    color: #FFF;
    font-weight:bold;
  }
  p{
    margin-bottom:0;
  }
}