.filter-section{
  text-align: right;
  padding:10px;
  ul{
    margin-bottom: 0;
    @include list-unstyled;
    li{
      display: inline-block;
    }
  }
  .title{
    @extend %font-regular;
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  .filter-value-item{
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    line-height: 1;

    padding:4px 5px 2px;
    margin-left: 5px;
    margin-bottom:3px;

    @include media-breakpoint-up(lg){
      padding:3px 4px 1px;
      margin-left: 4px;
      margin-bottom:2px;
    }

    @extend .link-undecorated;
    &:first-child{
      margin-left: 0;
    }
    &:hover{
      background-color: rgba(255,255,255,.1);
    }

    &.selected{
      background-color: rgba(255,255,255,.3);
    }
  }
  .block{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .filter-value-item{
    }
  }
}