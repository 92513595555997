.list-item.detail-opened {
  position: relative;

  @each $index, $color in $colors {
    &.bg-#{$index}:after {
      background-color: $blue;
      background-color: var(--#{$index});
    }
  }

  &:after {
    @include pseudo;
    background-color: $blue;
    background-color: var(--mainColor);
    height: 20px;
    width: 100%;
    @include absolute($bottom: -20px, $left: 0);
  }
}

.list-detail {
  position: relative;
  margin: 0 4px 15px;
  padding: 20px;
  color: var(--white);

  @include media-breakpoint-up(lg){
    padding:25px;
  }
  @include media-breakpoint-up(md) {
    min-height: 220px;
  }
  @include media-breakpoint-up(xl){
    padding:25px 35px 35px;
  }

  .link{
    color: var(--white);
  }

  h2 {
    margin-bottom: 12px;
  }

  h3 {
    margin-bottom: 2px;
  }

  .title {
    text-transform: uppercase;
  }

  a{
    @extend .link-decorated;
  }

  .table{
    p, ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul{
    @include list-unstyled;
    li{
      display: block;
    }
  }
}

.detail-actions {
  ul {
    @include list-unstyled;
    margin-bottom: 0;
  }

  .link {
    font-size: 32px;
    width: 100%;
    text-decoration: none;
    @include button-hover;
    &:after {
      font-size: 14px;
      position: static;
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    margin:20px -20px 0 -20px;
    margin-top: 20px;
    padding-top:20px;
    border-top: 1px solid rgba(255,255,255,.3);

    & > * {
      display: flex;
      justify-content: space-around;
    }

    ul li{
      padding-right:10px;
    }

    .link {
      display: flex;
      align-items: center;
      &:after{
        text-align: left;
      }
      .fas {
        margin-right: 5px;
      }
      &:hover .fas{
        &, &:before{
          text-decoration: none;
          display: inline-block;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    @include absolute($right:5px,$top:20px);
    & > * {
      display: flex;
      flex-direction: column;
      & > *{
        margin-bottom: 15px;
      }
    }
    .link {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width:70px;
      opacity: .7;
      &:after{
        visibility:hidden;
      }
      &:hover{
        opacity: 1;
        text-decoration: none;
      ;
        &:after{
          visibility: visible;
        }
      }
      .fas{
        padding-bottom: 5px;
      }
    }
  }
}


.gallery-video iframe{
  width:100%;
  height:75vh;
}

.list.page-list .list-detail{
  *:not(.detail-actions){
  p{
    line-height: 1.2rem;
  }
  ul:not([class*='wp-block']){
    list-style: disc outside none;
    padding-left:10px;
    li{
      display: list-item;
      margin-bottom: 4px;
    }
  }
  }
}