//Typography
.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.has-small-font-size {
  font-size: 13px;
  line-height: normal !important;
}

.has-medium-font-size {
  font-size: 24px;
  line-height: normal !important;
}

.has-large-font-size {
  font-size: 36px;
  line-height: normal !important;
}

.has-huge-font-size {
  font-size: 48px;
  line-height: normal !important;
}

.has-drop-cap:first-letter {
  font-size: 40px;
  @extend %font-bold;
  line-height: 35px;

}

//Tables
.has-fixed-layout {
  table-layout: fixed;
}

.is-style-stripes {
  tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, .2);
  }
}

//Media
.wp-block-image {
  img {
    max-width: 100%;
  }

  figcaption {
    margin-top: 6px;
  }
}

.wp-block-image {
  margin-bottom: 1.5rem;
}

.wp-block-embed {
  margin-bottom: 1.5rem;

  figcaption {
    text-align: center;
    margin-top: 6px;
  }
}

.wp-block-embed__wrapper {
  @extend .embed-responsive;
  @extend .embed-responsive-16by9;

  iframe {
    @extend .embed-responsive-item;
  }
}

.wp-block-embed.wp-has-aspect-ratio {
  &.wp-embed-aspect-4-3 {
    .wp-block-embed__wrapper {
      @extend .embed-responsive-4by3;
    }
  }

  &.wp-embed-aspect-1-1 {
    .wp-block-embed__wrapper {
      @extend .embed-responsive-1by1;
    }
  }
}

//Blocks
.wp-block-columns {
  @media (min-width: 530px) {
    display: flex;
    margin: 0 -10px 1.5rem;
    > .wp-block-column {
      padding: 0 10px 10px;
    }
  }
}

//Media text
.wp-block-media-text {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  img {
    max-width: 100%;
  }

  .wp-block-media-text__media {
    padding-right: 1.5rem;
  }

  &.has-media-on-the-right {
    .wp-block-media-text__media {
      order: 1;
    }
  }
}

//Gallery
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin: 30px -10px 30px;

  @media (min-width: 530px) {
    &.columns-3 {
      .blocks-gallery-item {
        width: calc((100% - 40px) / 3);
      }
    }

    &.columns-4 {
      .blocks-gallery-item {
        width: calc((100% - 60px) / 4);
      }
    }
  }

  .blocks-gallery-item {
    width: calc((100% - 20px) / 2);
    display: flex;
    padding: 0 10px 20px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    figure {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 100%;
      margin-bottom: 0;

      img {
        height: 100%;
        flex: 1;
        object-fit: cover;
        width: 100%;
        display: block;
        max-width: 100%;
      }

      figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 100%;
        overflow: auto;
        padding: 15px 0;
        color: #fff;
        text-align: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
      }
    }
  }
}


//file
.wp-block-file {
  margin: 20px 0;

  .wp-block-file__button {
    @extend .button;
    margin-left: 1rem;
  }
}

.wp-block-button {
  margin: 20px 0;

  .wp-block-button__link {
    @extend .button;
  }
}