/* text colors ------------------------------------- */
$blue: #009EE0;
$white: #FFF;

/* bg colors  ------------------------------------- */
$bgGrey: #CDCDCD;
$grey: #666;

/* Emphasizes colors */
$colors: (
  color0: #e74b2b,
  color1: #e5323d,
  color2: #54aa27,
  color3: #FFC30B,
  color4: #892A84,
  color5: #003577,
  color6: #E20079,
  color7: #9E1039,
  color8: #53672A,
  color9: #01b6d1,
  color10: #FD9215,
  color11: $blue,
);
