.spinner-overlay.visible{
  z-index:1120 !important;
}

.lds-ellipsis {
  display: inline-block;
  position: fixed;
  width: 124px;
  height: 34px;
  left: 50%;
  top: 50%;
  //280 is filter width,
  //transform: translate((-280px/2 - 64/2), calc(-50% + var(--header-height)/2));
  transform: translate(-50%, calc(-50% + var(--header-height)/2));
  z-index:1130;
}

.lds-ellipsis div {
  position: absolute;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0 0 3px var(--mainColor);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  z-index:12;
}

.lds-ellipsis div:nth-child(1) {
  left: 12px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 28px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 62px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 82px;
  z-index:10;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
