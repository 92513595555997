.menu{
  color: #FFF;
  color:var(--white);
  max-width:280px;
  width: 80%;
  transform: translate3d(100%, 0, 0);
  transition: transform .4s ease,-webkit-transform .4s ease;
  position: fixed;
  left: auto;
  right: 0;
  top:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  line-height: 20px;
  z-index: 30;
  a, button{
    color: var(--white);
    color:#FFF;
    @extend .link-undecorated;
  }
}
.menu-opened{
  transform: translate3d(0, 0, 0);
}

.menu-panels{
  background-color: $blue;
  background-color: var(--mainColor);
  color: #FFF;
  color:var(--white);
  position: relative;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  border-left:1px solid rgba(0,0,0,.1);
}

.menu-panel{
  @include absolute(0,0,0,0);
  color: #FFF;
  color:var(--white);
  z-index: 0;
  display: block;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  width:100%;
  //transition: transform .4s ease,-webkit-transform .4s ease;
}
.menu-listview {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.menu-listitem{
  text-align:right;
  @extend %font-bold;
  text-transform: uppercase;
  font-size: 120%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 37px;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  color: #FFF;
  color:var(--white);

  &:after{
    display: none;
  }
  .menu-listitem__text{
    color: #FFF;
    color:var(--white);
    padding:10px 20px 8px;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //flex-grow: 1;
    //flex-basis: 10%;
    display: block;
    border-top:1px solid rgba(255,255,255,.3);
    &:hover{
      background-color: rgba(255,255,255,.1);
    }
    &.active{
      background-color: rgba(255,255,255,.3);
    }
  }
  &:first-child{
    .menu-listitem__text{
      border-top: none;
    }
  }

  &.with-submenu{
    background-color: rgba(255, 255, 255, .1);
    //todo animate submenu
    & > .menu-listitem__text{
      background-color: transparent;
    }
    .submenu{
      display: block;
    }
  }

  .submenu{
    display: none;
    padding: 0 0 8px;
    .menu-listitem{
      font-size:90%;
      .menu-listitem__text{
        padding: 8px 30px 6px 10px;
        border-top: none;
        &:hover{
          border-bottom: 1px solid rgba(255, 255, 255, .1);
          padding-bottom:5px;
        }

        @include media-breakpoint-up(md){
          padding: 6px 30px 4px 10px;
          &:hover {
            padding-bottom: 3px;
          }
        }
      }
    }
  }

}

.menu-overlay{
  &.visible {
    opacity: .5;
    background-color: $blue;
    background-color: var(--mainColor);
    height: calc(100vh - 60px);
    height: calc(100vh - var(--header-height));
    width: 100%;
    position: fixed;
    top:60px;
    top: var(--header-height);
    z-index: 20;
  }

  &.clear{
    background-color: transparent;
  }
}
.spinner-overlay{
  @extend .menu-overlay;
  &.visible {
    opacity: .2;
  }
}

//navbars
.menu-navbar{
  background-color: $blue;
  background-color: var(--mainColor);
  align-items: center;
  height: 58px;
  height: calc(var(--header-height) - 2px);
  min-height: 60px;
  color: var(--white);
  display: flex;
  opacity: 1;
  transition: opacity .4s ease;
  @include media-breakpoint-down(sm){
    margin-top: -10px;
  }

  & > *{
    opacity: .85;
    color: var(--white);
    display:block;
    &:hover {
      color: var(--white);
      opacity: 1;
    }
  }
}

.menu-navbars_top,
.menu-navbars_bottom{
  position: relative;
  flex-shrink: 0;
  background-color: $blue;
  background-color: var(--mainColor);
}

.menu-navbars_top{
  border-bottom:1px solid rgba(0,0,0,.1);

  .menu-navbar {
    justify-content: flex-end;
    padding-right: 15px;

    & > *{
      display: inline-block;
      flex-grow: 0 !important;
      padding:0 10px;
      font-size:24px;
      @include button-hover;
    }

    .menu-logo{
      @include absolute($left: 15px);
      margin-left: 0;
      img{
        max-width: 30px;
      }
    }
  }
}

.menu-navbars_bottom{
  border-top:1px solid rgba(0,0,0,.1);
  border-left:1px solid rgba(0,0,0,.1);
}

.menu-partner{
  @extend %partner-icon;
  padding: 0 10px;
  img{
    max-width: 100%;
    --maxHeight: none;
    max-height: 20px;
    max-height: var(--maxHeight);
  }
  .fa-2x{
    --fontSize: 2em;
    font-size: 1.6em;
    font-size: var(--fontSize);
  }
}

.form-panel{
  z-index:10;
  background-color: $blue;
  background-color: var(--mainColor);
  padding:15px;
}