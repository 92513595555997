/** custom fonts ---------------------------- */
@font-face {
  font-family: 'teuton_magerregular';
  src: url('../fonts/teutonmager-webfont.eot');
  src: url('../fonts/teutonmager-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/teutonmager-webfont.woff') format('woff'), url('../fonts/teutonmager-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'teuton_magerbold';
  src: url('../fonts/teutonmagerbold-webfont.eot');
  src: url('../fonts/teutonmagerbold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/teutonmagerbold-webfont.woff') format('woff'), url('../fonts/teutonmagerbold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}