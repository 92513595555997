$listItemWidth: 216px;

.list{
  margin-bottom: 30px;

  a, button{
    color:var(--white);
  }

  .list-actions{
    ul{
      @include list-unstyled;
      margin-bottom: 0;
      .button{
        margin-left: 6px;
        margin-bottom: 10px;
        letter-spacing: 0.01em;
        &.active {
          color: $blue;
          color: var(--mainColor);
          background-color: var(--white);
        }
      }

      @include media-breakpoint-only(xs){
        li{
          display: flex;
        }
        .button{
          display: flex;
          margin-left: 0;
          align-items: center;
        }
      }
    }
  }

  .list-header{
    .list-navigation{
      min-height: 48px;
      margin: 0 auto;
      display:flex;
      justify-content: space-between;
      text-align: center;

      & > :first-child .button{
        margin-left: 0;
      }

      @include media-breakpoint-up(sm) {
        height: 36px;
        min-height: 0;
        width: 2*($listItemWidth) + 8px;
      }
      @media (min-width: 692px) {
        display: block;
        text-align: right;
        width: 3*($listItemWidth) + 2*8px;
      }
      @media (min-width: 916px) {
        width: 4*($listItemWidth) + 3*8px;
      }

      @media (min-width: 1140px) {
        width: 5*($listItemWidth) + 4*8px;
      }
    }
  }

  .list-title{
    color: var(--white);
    padding:5px 8px;
    margin-bottom: 10px;
    font-size:1rem;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
  }


  .list-footer{
    text-align: center;
  }

  .list-body{
    margin: 0 -5px; //clear gap created by items' padding
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(sm){
      min-height: 100px
    }
  }

  .info-message{
    background-color: fade-out(#FFF,.2);
    border: 1px solid $blue;
    border-color: var(--mainColor);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 15px;
    justify-content: center;
    align-items: center;
    margin:0 15px;
    @extend %font-bold;
    font-size:24px;
    .button{
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .list-item{
    margin: 0 4px 10px;
    width:$listItemWidth;
    max-width:$listItemWidth;
    color: var(--white);


    .headline{
      text-transform: uppercase;
      margin:4px 0 5px;
      font-size:24px;
    }

    .supheadline{
      span{
        display:block;
      }
    }

    .content{
      padding:10px 12px 0;
      height:198px;
      overflow: hidden;
      border-bottom: 6px solid transparent;

      @each $index, $color in $colors {
        &.bg-#{$index} {
          border-bottom-color: var(--#{$index});
        }
      }

    }

    .description{
      line-height: 1;
    }
  }

  .link {
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover {
      .image {
        opacity: .8;
      }
    }
  }

  .image-wrap{
    width:100%;
    max-height:$listItemWidth;
    position: relative;
    overflow: hidden;
    &:after{
      @include pseudo;
      @include absolute($left: 32px, $top:0);
      @include arrow-down(18px,16px);
      border-top-color: $blue;
      border-top-color: var(--mainColor);
    }
    .image{
      width:100%;
      height:auto;
    }
  }

  @include media-breakpoint-up(md){
    margin-bottom: 15px;
    .list-title{
      margin-bottom: 8px;
    }
  }
}

.list-title{
  position:relative;
  @include media-breakpoint-up(xl) {
    left: -30px;
  }

  &:after{
    @include pseudo;
    @include absolute($left:1px,$bottom:-7.5px);
    @include arrow-down(10px,10px);
    transform: rotate(-135deg);
    opacity: .8;
    border-top-color: $blue;
    border-top-color: var(--mainColor);
  }
}

.list .list-carousel{
  @extend .list-body;
  justify-content: flex-start !important;
  margin-left:auto;
  margin-right:auto;
  width: $listItemWidth + 8px + 60px; //8 item padding; 60px carousel padding

  @include media-breakpoint-between(xs,lg) {
    padding: 0 30px;
  }

  @include media-breakpoint-up(sm) {
    width: 2 * ($listItemWidth + 8px) + 60px; //8 item padding; 60px carousel padding
  }

  @include media-breakpoint-up(md) {
    width: 3 * ($listItemWidth + 8px) + 60px; //8 item padding; 60px carousel padding
  }

  @include media-breakpoint-up(lg) {
    width: 4 * ($listItemWidth + 8px) + 60px; //8 item padding; 60px carousel padding
  }

  @include media-breakpoint-up(xl) {
    width: 5 * ($listItemWidth + 8px); //8 item padding
  }


  @for $i from 1 through 15{
    &.list-items-#{$i}{
      .slick-track {
        min-width: $i*($listItemWidth + 8px);
      }
    }
  }

  .slick-slide{
    padding:0 4px;
    width: $listItemWidth + 8px !important; //8px for padding
  }

  @media (min-width: 531px){
    .list-item{
      margin-bottom: 0;
    }
  }


  .carousel-icon{
    @extend %font-bold;
    position: absolute;
    height:100%;
    color: #DEDEDE;
    background-color: transparent;
    font-size:50px;
    width:28px;
    border: none;
    outline: none;
    box-shadow: none;
    appearance: none;
    padding:0;

    &:hover{
      color: #333;
    }

    &:before{
      display:block;
      @include absolute($top: 75%);
      transform: translate(0,-50%);
    }

    &.prev{
      left: 0;
      &:before {
        content: "⟨";
      }
      @include media-breakpoint-up(xl){
        left:-30px;
      }
    }

    &.next{
      right:0;
      &:before {
        content: "⟩";
        right:0;
      }
      @include media-breakpoint-up(xl){
        right:-30px;
      }
    }

    &.slick-disabled{
      cursor: default;
      &:before{
        display: none;
      }
    }
  }
}

.list.page-list{
  margin-top:36px;
  .list-body{
    justify-content: flex-start;
    table{
      @extend .table;
      margin-bottom: 30px;
    }
  }
}

